import React from 'react';

const Contact = () => {
  return (
    <section className="contact" id="contact">
      <h2 className="heading">
        Contact <span>Me!</span>
      </h2>
      <div className="contact-container">
        <div className="contact-info">
          <i className='bx bx-mail-send'></i>
          <div className="contact-details">
            <h4>Email</h4>
            <p>toupencetom@gmail.com</p>
          </div>
        </div>
        <div className="contact-info">
          <i className='bx bxs-map'></i>
          <div className="contact-details">
            <h4>Address</h4>
            <p>Villeurbanne, France</p>
          </div>
        </div>
        <div className="contact-info">
          <i className='bx bxs-phone-call'></i>
          <div className="contact-details">
            <h4>Phone number</h4>
            <p>+33 6 13 77 24 95</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
