import React from 'react';

const About = () => {
  return (
    <section className="about" id="about">
      <div className="about-img">
        <img src={require('../img/img2.png')} alt="About Image"/>
      </div>
      <div className="about-content">
        <h2 className="heading"> About <span>Me</span></h2>
        <p> My name is Tom, I'm 21 and I'm currently studying in Computing Engineering at Polytech. My hobbies are mainly playing basketball, board or video games and science. My assets are my curiosity, my seriousness and my hard work.</p>
      </div>
    </section>
  );
};

export default About;
