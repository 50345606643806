import React from 'react';

const Portfolio = () => {
  return (
    <section className="portfolio" id="portfolio">
      <h2 className="heading"> Latest <span> Projects </span></h2>
      <div className="portfolio-container">
        <div className="portfolio-box">
          <img src={require('../img/feu_foret.gif')} alt="Forest fire project"/>
          <div className="portfolio-layer">
            <h4>Forest fire project</h4>
            <p>Project developed in Java where we can try the automata 1D and the Forest fire.</p>
            <a href="https://github.com/tom-toupence/Automates_cellulaires"><i className='bx bx-link'></i></a>
          </div>
        </div>
        <div className="portfolio-box">
          <img src={require('../img/portfolio2.gif')} alt="Web Film Project"/>
          <div className="portfolio-layer">
            <h4>Web Film Project</h4>
            <p>Project developed in .NET, React where we can grab some information on different films.</p>
            <a href="https://github.com/tom-toupence/Projet-Tutore-3A"><i className='bx bx-link'></i></a>
          </div>
        </div>
        <div className="portfolio-box">
          <img src={require('../img/portfolio3.png')} alt="Web Shop"/>
          <div className="portfolio-layer">
            <h4>Web Shop</h4>
            <p>Project developed using MVC architecture with PHP, PHPMyAdmin, and the Twig template engine.</p>
            <a href="https://github.com/tom-toupence/php_project"><i className='bx bx-link'></i></a>
          </div>
        </div>
        <div className="portfolio-box">
          <img src={require('../img/Sokoban.PNG')} alt="Sokoban"/>
          <div className="portfolio-layer">
            <h4>Sokoban</h4>
            <p>Project/Game developed using Java.</p>
            <a href="https://github.com/tom-toupence/Sokoban"><i className='bx bx-link'></i></a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
