import React from 'react';

const Home = () => {
  return (
    <section className="home" id="home">
      <div className="home-content">
        <h3>Welcome, I'm </h3>
        <h1>TOUPENCE Tom</h1>
        <h3>I'm <span className="multiple-text"></span> </h3>
        <p>Scroll down to find out more information about me!</p>
        <div className="social-media">
          <a href="https://github.com/tom-toupence"><i className='bx bxl-github'></i></a>
          <a href="https://www.facebook.com/profile.php?id=100009449157210"><i className='bx bxl-facebook-circle' ></i></a>
          <a href="https://www.linkedin.com/in/tom-toupence-20117429a/"><i className='bx bxl-linkedin-square' ></i></a>
        </div>
        <a href={require('../img/CV.pdf')} target="_blank" className="btn">Curriculum Vitae</a>
      </div>
      <div className="home-img">
        <img src={require('../img/img1.png')} alt="Home Image"/>
      </div>
    </section>
  );
};

export default Home;
