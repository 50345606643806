import React, { useEffect, useRef } from 'react';
import ScrollReveal from 'scrollreveal';
import Typed from 'typed.js';
import './css/style.css';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () => {
  const typedInstance = useRef(null);

  useEffect(() => {
    const parallax = document.querySelector('.home');
    
    window.addEventListener('scroll', () => {
      if (parallax) {
        parallax.style.backgroundPositionY = window.scrollY / 1.2 + "px";
      }
    });
  
    let menuIcon = document.querySelector('#menu-icon');
    let navbar = document.querySelector('.navbar');
  
    if (menuIcon && navbar) {
      menuIcon.onclick = () => {
        menuIcon.classList.toggle('bx-x');
        navbar.classList.toggle('active');
      };
    }
  
    let sections = document.querySelectorAll('section');
    let navLinks = document.querySelectorAll('header nav a');
  
    window.onscroll = () => {
      sections.forEach(sec => {
        let top = window.scrollY;
        let offset = sec.offsetTop - 150;
        let height = sec.offsetHeight;
        let id = sec.getAttribute('id');
  
        if (top >= offset && top < offset + height) {
          navLinks.forEach(links => {
            links.classList.remove('active');
            document.querySelector('header nav a[href*=' + id + ']').classList.add('active');
          });
        }
      });
  
      if (menuIcon && navbar) {
        menuIcon.classList.remove('bx-x');
        navbar.classList.remove('active');
      }
    };
  
    ScrollReveal({ 
      reset: true,
      distance: '80px',
      duration: 2000,
      delay: 200
    });
  
    ScrollReveal().reveal('.home-content, .heading', { origin: 'top' });
    ScrollReveal().reveal('.home-img, .portfolio-box, .contact-info', { origin: 'bottom' });
    ScrollReveal().reveal('.home-content h1, .about-img img', { origin: 'left' });
    ScrollReveal().reveal('.home-content p, .about-content', { origin: 'right' });
  
    typedInstance.current = new Typed('.multiple-text', {
      strings: ['a student in Computer Engineering.'],
      typeSpeed: 100,
      loop: false,
    });
  
    return () => {
      window.removeEventListener('scroll', null);
      if (typedInstance.current) {
        typedInstance.current.destroy();
      }
    };
  }, []);
  
  return (
    <div>
      <Header />
      <Home />
      <About />
      <Portfolio />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
